<template>
  <v-container id="about-us" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="mt-0" shaped>
          <v-card-title class="success">
            <v-btn class="mx-1" text to="/">
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <v-spacer></v-spacer> 
            <div class="display-2 white--text" color="white">HOW TO USE THIS SITE</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-subtitle> </v-card-subtitle>
          <v-card-text>
            <br/>
            <p>Summary on how the site works:</p>
            <ol>
              <li>
                You need to register/login in order to browse the details of
                this Site. Please check your email after registration to be
                admitted.
              </li>
              <li>
                Users can contact farmers/ LGUs directly to inquire to buy
                products
              </li>
            </ol>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
